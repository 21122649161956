@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-zinc-100;
}

.header1 {
  @apply text-xl font-bold text-stone-700;
}

.header2 {
  @apply text-base text-stone-800;
}

.header3 {
  @apply text-lg font-bold text-stone-700;
}

.header4 {
  @apply text-xs font-bold text-stone-800;
}

.rounddash {
  @apply p-10 rounded-lg;
}
